body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14pt;
  font-style: normal;
  background-color: #FCFCFC;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-size: 42pt;
  font-weight: 400;
}

h2 {
  font-size: 32pt;
  font-weight: 400;
  padding-top: 20px;
  margin-block-end: 15px;
}

h3 {
  font-size: 26pt;
  font-weight: 400;
  margin-block-start: 30px;
  margin-block-end: 0px;
}

h4 {
  font-size: 18pt;
}

a {
  color: #fca006;
}

p {
  line-height: 1.8em;
}

ul {
  margin-block-start: 0px;
}

ul li {
  line-height: 1.8em;
}

button {
  background-color: black;
  color: white;
  font-size: larger;
  padding: 20px 30px 20px 30px;
  cursor: pointer;
}

button a {
  color: white;
  text-decoration: none;
}

button.bg-red {
  background-color: red;
  padding: 5px;
}

button.bg-orange {
  background-color: #fca006;
}

textarea {
  width: 100%;
  height: 60px;
  font-size: 16pt;
}

table {
  border-spacing: 0px;
}

div.dashboard-card {
  background-color: white;
  padding: 10px;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  vertical-align: top;
  text-align: 'left';
}

h2.dashboard-title {
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-top: -30px;
  margin-right: 7px;
  margin-left: 7px;
  background-color: orange;
  color: white;
  font-size: larger;
}

.container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content:center;
  align-items: auto;
  align-content: flex-start
}
.item {
  flex: 1 0 auto;
  margin: 10px;
  min-width: 320px;
  max-width: 60vw;
  align-self: flex-start;
}

.light-grey-bg {
  background-color: #FCFCFC;
}

.content-wrapper {
  padding-right: 8vw;
  padding-left: 8vw;
  margin: 0 auto;
  box-sizing: content-box;
}

.dark-section {
  background-color: black;
  color: white;
  padding: 30px 0px 30px 0px;
}

.orange-section {
  background-color: #fca006;
  color: black;
  padding: 30px 0px 30px 0px;
}

.v-padding30 {
  padding: 30px 0px 30px 0px;
}

.pointer {
  cursor: pointer;
}

.bottom-label {
  border-radius: 15px;
  position: absolute;
  bottom: -30px;
  width: 300px;
  text-align: center;
  color: white;
  width: 80%;
}

.label {
  border-radius: 15px;
  padding: 5px 0px 5px 20px;
  color: white;
  width: 80%;
}

.label:hover {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.label-normal {
  border-radius: 15px;
  padding: 10px 0px 10px 20px;
  color: white;
}

.close-button {
  color: #000;
  font-size: 28px;
  font-weight: bold;
  text-decoration: none;
}

.close-button:hover,
.close-button:focus {
  color: #666;
  text-decoration: none;
  cursor: pointer;
}

.no-padding {
  padding: 0px;
}

.inline {
  display: inline-block;
}

/* Tooltip container */
.tooltip {
  position: relative;
  display: inline-block;
  margin: 2px;
  /* If you want dots under the hoverable text */
}

/* Tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  top: -5px;
  right: 105%;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltip .tooltiptext-top {
  visibility: hidden;
  width: 200px;
  bottom: 100%;
  left: 50%;
  margin-left: -15px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext-top {
  visibility: visible;
}

.hidden {
  display: none;
}

.no-underline {
  text-decoration: none;
}

.margin5 {
  margin: 5px;
}

.padding5 {
  padding: 5px;
}

.rounded {
  border-radius: 10px;
}

.react-datepicker__input-container>input {
  height: 30px;
  padding: 10px;
  font-size: 16pt;
}

@media screen and (max-width: 600px) {
  body {
    font-size: 12pt;
  }

  h1 {
    font-size: 24pt;
    font-weight: 400;
  }

  h2 {
    font-size: 20pt;
    font-weight: 400;
  }

  h3 {
    font-size: 16pt;
    font-weight: 400;
  }

  h4 {
    font-size: 12pt;
  }

  textarea {
    font-size: 12pt;
  }

  .content-wrapper {
    padding-right: 4vw;
    padding-left: 4vw;
  }
}