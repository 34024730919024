.card {
  width: 300px;
  height: 300px;
  color: black;
  display: inline-block;
  font-size: 2vh;
  position: relative;
  transition: all .2s ease-in-out;
}

.card:hover {
  transform: scale(1.1);
}

.card h3 {
  font-size: 3vh;
}

.card a {
  text-decoration: none;
}