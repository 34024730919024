img {
  max-width: 100%;
}


.bloggridrow:after {
  content: "";
  display: table;
  clear: both;
}

.blogGridLeftCol {
	float: left;
  width: 70%;
}

.blogGridRightCol {
	float: left;
  width: 30%;
  display: flex;
  flex-wrap: wrap;
}

.readmore {
	text-decoration: none;
	margin-top: 10px;
}

.tagContainer:hover {
  background-color: orange;
  color: white;
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  
  .blogGridLeftCol {
    width: 100%;
  }

  .blogGridRightCol {
    width: 100%;
  }
}