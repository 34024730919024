li {
  line-height: 22px;
}

.page-section {
  align-items: flex-end;
  min-height: 33vh;

  padding-top: 30vh;
}

.category-block {
  display: inline-block;
  margin: 5px;
  width: auto;
}


@media screen and (max-width: 600px) {
  .page-section {
    align-items: flex-end;
    min-height: 33vh;
  
    padding-top: 40vh;
  }
}