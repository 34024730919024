.search-input {
  width: 100%;
  min-height: 40px;
  font-size: 18pt;
  margin-left: 10px;
  margin-right: 10px;
}

.inline {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  justify-content: space-between;
}

input[type="text"].search-input
{
  background: transparent;
  border: none;
}

.no-border {
  border: 0px;
}

.border {
  border: thin solid #aaa;
  padding: 10px;
}

/* The Modal (background) */
.modal {
  display: none;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 300px;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
  background-color: #fefefe;
  margin: auto;  
  border: 1px solid #888;
  width: 95%;
}

.modal-content ul {
  margin: 0px;
  padding: 0px;
  margin-block-start: 0px;
}


.modal-content li {
  list-style-type: none;
  margin: 0px;
  text-align: left;
  padding: 10px 10px 10px 10px;
}

.modal-content li a{
  text-decoration: none;
  font-weight: bold;
  
}

.orangeColor {
  color: #fca006
}

.relatedJobs {
  font-size: 12pt;
  color: gray;
  padding: 5px 0 10px 0;
}

.relatedJobs span{
  
}

.clearFloat {
  clear: both;
}

.label:hover {
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.label-normal {
  border-radius: 15px;
  padding: 10px 0px 10px 20px;
  color: white;
}

/* The Close Button */
.close {
  display: none;
  color: #aaaaaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.active {
  display: block;
}

.transparent {
  background-color:rgba(0, 0, 0, 0);
}

.white{
  color: white;
}