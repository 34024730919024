li.nav-item {
  list-style-type: none;
  font-size: 10vw;
}

.Header {
  position: relative;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
  padding-top: 1vh;
  padding-bottom: 3vw;
  padding-left: 4vw;
  padding-right: 4vw;
  pointer-events: auto;

  display: flex;
  flex-wrap: nowrap;
  flex: 1 0 67%;
  align-items: center;
}

.header-logo {
  flex-grow: 0;
  flex-shrink: 0;
  backface-visibility: hidden;
}

.logo {
  width: auto;
  max-width: 100%;
  max-height: 88px;
}

.header-nav {
  text-align: right;
  margin-left: auto;
  padding-left: 3.5vw;
  flex-grow: 1;
  flex-shrink: 1;
}

.header-nav-list {
  justify-content: flex-end;
  display: inline-flex;
  flex-wrap: wrap;
}

.header-nav-list a {
  text-decoration: none;
  color: black;
}

.header-nav-list>div {
  white-space: nowrap;
}

.header-nav-item {
  line-height: 1.8em;
  padding: 10px;
}

.top-right {
  position: absolute;
  top: 25px;
  right: 25px;
}

.menu-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: white;
}

@media screen and (max-width: 1511px) and (min-width: 0) and (orientation: landscape) {
  .header-nav-item,
  .user-accounts-text-link,
  .cart-text-link {
    font-size: calc((1 - 1) * 1.2vw + 1rem);
  }
}

@media screen and (max-width: 600px) {
  .logo {
    max-height: 44px;
  }
}