progress {
  border: none;
  width: 50%;
  height: 25px;
  color: blue;
  border-radius: 0px 5px 5px 0px;
  border-left: thin solid #DDD;
}

progress::-webkit-progress-bar {
  background: none;
  border-radius: 0px 5px 5px 0px;
  border-left: thin solid #DDD;
}

progress::-webkit-progress-value {
  border-radius: 0px 5px 5px 0px;
  border-left: thin solid #DDD;
}

progress::-webkit-progress-value { background: orange; }
progress::-moz-progress-value { background: orange; }

progress.logical::-webkit-progress-value { background: #939393; }
progress.logical::-moz-progress-value { background: #939393; }

progress.musical::-webkit-progress-value { background: #655497; }
progress.musical::-moz-progress-value { background: #655497; }

progress.visual::-webkit-progress-value { background: #4268a3; }
progress.visual::-moz-progress-value { background: #4268a3; }

progress.verbal::-webkit-progress-value { background: #f8b902; }
progress.verbal::-moz-progress-value { background: #f8b902; }

progress.bodily::-webkit-progress-value { background: #fca006; }
progress.bodily::-moz-progress-value { background: #fca006; }

progress.interpersonal::-webkit-progress-value { background: #bd5ac6; }
progress.interpersonal::-moz-progress-value { background: #bd5ac6; }

progress.intrapersonal::-webkit-progress-value { background: #8ccabb; }
progress.intrapersonal::-moz-progress-value { background: #8ccabb; }

progress.naturalistic::-webkit-progress-value { background: #7dad3d; }
progress.naturalistic::-moz-progress-value { background: #7dad3d; }
