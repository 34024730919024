.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fullHeight {
  height: 100%;
}

/* x-grid css, does not work dynamically */
.MuiButtonBase-root.Mui-disabled {
  cursor: pointer !important;
  pointer-events: inherit !important;
}

.MuiDataGrid-root .MuiButton-textPrimary,
.MuiDataGridPanel-root .MuiButton-textPrimary {
  color: orange !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
