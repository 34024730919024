.skill-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 50%;
  left: 0;
  background: black;
  overflow: hidden;
}

.skill-background img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-position: 50% 50%;
  object-fit: cover;
  opacity: 0.5;
}

.skill-header {
  position: relative;
  padding-top: 15vh;
  padding-left: 8vw;
  max-width: 80vw;
  color: white;
}