li.skill-list-item {
  list-style-type: none;
  padding: 0px;
}

ul.skill-list {
  padding: 0px;
}

.dot {
  width: 20px;
  height: 20px;
  margin: 3px;
}

@media screen and (max-width: 600px) {
  .dot {
    width: 13px;
    height: 13px;
  }
}